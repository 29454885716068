<section>
  <div class="imprint">
    <h1>{{ selectedLanguage === 'EN' ? 'Imprint' : 'Impressum' }}</h1>
  
    <p class="§">
      <strong>{{ selectedLanguage === 'EN' ? 'Information according to § 5 TMG (Germany) / § 14 UGB (Austria) and Art. 3 Abs. 1 lit. s. UWG (Switzerland):' : 'Angaben gemäß § 5 TMG (Deutschland) bzw. § 14 UGB (Österreich) und Art. 3 Abs. 1 lit. s. UWG (Schweiz):' }}</strong>
    </p>
  
    <p>
      {{ selectedLanguage === 'EN' ? 'Name' : 'Name' }}<br />
      Paul Ivan<br/>
      Hauptstraße 30<br/>
      4020 Linz<br/>
      Österreich 
    </p>
  
    <p>
      <strong>{{ selectedLanguage === 'EN' ? 'Contact:' : 'Kontakt:' }}</strong><br />
      E-Mail: info&#64;paul-ivan.com
    </p>
  
    <p>
      <strong>{{ selectedLanguage === 'EN' ? 'Liability for content:' : 'Haftung für Inhalte:' }}</strong><br />
      {{ selectedLanguage === 'EN' ? 
        'As a service provider, I am responsible for my own content on these pages according to § 7 Abs.1 TMG (Germany) / Art. 2 UWG (Switzerland) according to the general laws. However, according to §§ 8 to 10 TMG (Germany), I am not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.' :
        'Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG (Deutschland) / Art. 2 UWG (Schweiz) für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG (Deutschland) bin ich als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.' 
      }}
    </p>
  
    <p>
      <strong>{{ selectedLanguage === 'EN' ? 'Liability for links:' : 'Haftung für Links:' }}</strong><br />
      {{ selectedLanguage === 'EN' ? 
        'My offer contains links to external websites of third parties, on whose contents I have no influence. Therefore, I cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages.' : 
        'Mein Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.' 
      }}
    </p>
  
    <p>
      <strong>{{ selectedLanguage === 'EN' ? 'Copyright:' : 'Urheberrecht:' }}</strong><br />
      {{ selectedLanguage === 'EN' ? 
        'The contents and works created by the site operator on these pages are subject to copyright law. Contributions from third parties are marked as such.' : 
        'Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet.' 
      }}
    </p>
  </div>
</section>